// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://va57dzzxujgtrlih5edlpey46a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hprrviiw2zb4xj5boe22yf3x54",
    "aws_content_delivery_bucket": "studyguide-appsync-20200206093925-hostingbucket-devtwo",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d27jk87l2e33hx.cloudfront.net"
};


export default awsmobile;
