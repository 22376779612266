import React from 'react';
import logo from './logo.svg';
import Amplify, {API, graphqlOperation} from 'aws-amplify';
import awsconfig from './aws-exports';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';
import './App.css';



Amplify.configure(awsconfig);



function App() {
  // Simple call to a mutations
  async function putData () {
    const newStudentProficiency = await API.graphql(graphqlOperation(mutations.createStudentProficiency,
        {
          student: 'chadwick',
          proficiencyScore: 98.12
        }
      )
    )
    console.log(newStudentProficiency);
  }

  async function getData() {
    // Simple query
    const LearningResources = await API.graphql(graphqlOperation(queries.getLearningResources));
    console.log(LearningResources);

    // Query using a parameter
    const Learningresource = await API.graphql(graphqlOperation(queries.getLearningResource, { id: 'some id' }));
    console.log(Learningresource);
  }


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <title>Study guide test page</title>
        <a
        
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
         Study Guide Application
        </a>
      </header>
    </div>
  );
}

export default App;
